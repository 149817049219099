import imgProfile from './images/luis-ponce-sanchez.jpg';
import iconMail from './images/mail.svg';
import iconIn from './images/in.svg';
import './css/app.css';

function App() {
  return (
    <div className="wrapper">
      <div className="inner-box">
        <div className="rounded-overlay">
          <img src={imgProfile} alt="Luis Ponce Ingeniero Informático" />
        </div>
        <h1>Luis Ponce</h1>
        <h2>Diseñador <b>Gráfico</b><br/>
        ING. <b>Informático</b></h2>

        <div className="social">
          <div className="social-item">
            <a 
              href="https://www.linkedin.com/in/luisponcesanchez/" 
              target="_blank" 
              rel="noreferrer">
              <img src={iconIn} alt="Perfil en linked in" />
            </a>
          </div>
          <div className="social-item">
            <a 
              href="mailto:contacto@luisponce.cl" 
              target="_blank" 
              rel="noreferrer">
              <img src={iconMail} alt="Enviar correo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
